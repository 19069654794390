<template>
    <div>
        <newHeader url="/index"></newHeader>
        <el-row style="background: #f1f1f1;padding: 10px 0;" :gutter="10" justify="center" type="flex">
            <el-col :span="4">
                <el-card class="myAvatar">
                    <img src="../../assets/images/userInfo.jpg" height="64" width="64" class="avatar"/>
                    <h3>jane</h3>
                    <p class="my-item my-item-visited">个人资料</p>
                    <p class="my-item">业务信息</p>
                    <p class="my-item">金融产品</p>
                </el-card>
            </el-col>
            <el-col :span="10">
                <el-card>
                    <div>
                        <fieldset class="layui-elem-field layui-field-title site-title">
                            <legend><a class="field-title">注册信息</a></legend>
                        </fieldset>
                        <dl>
                            <dt class="table-dt">企业名称</dt>
                            <dd class="table-dd"></dd>
                            <dt class="table-dt">企业地址</dt>
                            <dd class="table-dd"></dd>
                            <dt class="table-dt" style="border-top: none;border-bottom: none">注册人姓名</dt>
                            <dd class="table-dd" style="border-top: none;border-bottom: none;"></dd>
                            <dt class="table-dt" style="border-top: none;border-bottom: none">注册人手机号码</dt>
                            <dd class="table-dd" style="border-top: none;border-bottom: none;"></dd>
                            <dt class="table-dt">用户名</dt>
                            <dd class="table-dd"></dd>
                            <dt class="table-dt"></dt>
                            <dd class="table-dd"></dd>
                        </dl>
                        <div style="text-align: center">
                            <el-button type="primary" plain round>修改密码</el-button>
                            <el-button type="primary" plain round>修改手机号</el-button>
                        </div>
                    </div>
                    <div>
                        <fieldset class="layui-elem-field layui-field-title site-title">
                            <legend><a class="field-title">企业信息</a></legend>
                        </fieldset>
                        <dl>
                            <dt class="table-dt">企业名称</dt>
                            <dd class="table-dd"></dd>
                            <dt class="table-dt">营业执照号</dt>
                            <dd class="table-dd"></dd>
                            <dt class="table-dt" style="border-top: none;border-bottom: none">企业法人</dt>
                            <dd class="table-dd" style="border-top: none;border-bottom: none;"></dd>
                            <dt class="table-dt" style="border-top: none;border-bottom: none">法人身份证号</dt>
                            <dd class="table-dd" style="border-top: none;border-bottom: none;"></dd>
                            <dt class="table-dt">开户银行</dt>
                            <dd class="table-dd"></dd>
                            <dt class="table-dt">开户账号</dt>
                            <dd class="table-dd"></dd>
                        </dl>
                        <div style="text-align: center">
                            <div class="zhizhao">
                                <img src="../../assets/images/zhizhao.png" height="195px" width="280px"/>
                                <div>企业营业执照</div>
                            </div>
                            <div class="zhizhao">
                                <img src="../../assets/images/zhizhao.png" height="195px" width="280px"/>
                                <div>企业经营许可证</div>
                            </div>
                            <div style="margin-top: 50px">
                                <el-button type="primary" plain round @click="editCompanyInfo">更新信息</el-button>
                            </div>
                        </div>

                    </div>
                </el-card>
            </el-col>
        </el-row>
        <el-dialog :visible.sync="dialogTableVisible" :show-close="false" width="1000px">
            <fieldset class="layui-elem-field layui-field-title site-title" style="margin: 0px 30px;">
                <legend><a class="field-title">企业信息</a></legend>
            </fieldset>
            <el-form ref="form" :model="form" :rules="rules" label-width="320px" :inline="true" size="medium" style="padding: 0 0 0 70px;" label-position="left">
                <el-form-item label="企业名称" style="width: 400px;margin-right: 40px;" prop="name">
                    <el-input v-model="form.name" ></el-input>
                </el-form-item>
                <el-form-item label="企业执照号统一社会信用编码" style="width: 400px;margin-right: 40px;" prop="name">
                <el-input v-model="form.name" ></el-input>
                </el-form-item>
                <el-form-item label="企业法人" style="width: 400px;margin-right: 40px;" prop="name">
                <el-input v-model="form.name" ></el-input>
                </el-form-item>
                <el-form-item label="法人身份证" style="width: 400px;margin-right: 40px;" prop="name">
                <el-input v-model="form.name" ></el-input>
                </el-form-item>
                <el-form-item label="开户银行" style="width: 400px;margin-right: 40px;" prop="name">
                    <el-input v-model="form.name" ></el-input>
                </el-form-item>
                <el-form-item label="开户银行账号" style="width: 400px;margin-right: 40px;" prop="name">
                    <el-input v-model="form.name" ></el-input>
                </el-form-item>
                <el-form-item label="营业执照" style="width: 400px;margin-right: 40px;" prop="name">
                    <el-image style="width: 200px; height: 200px">
                        <div slot="error" class="image-slot">
                            点击上传
                        </div>
                    </el-image>
                    <div class="tips">
                        <p>提示:</p>
                        <p>格式:JPG/PNG</p>
                        <p>尺寸:200x200</p>
                        <p>大小:小于400kb</p>
                    </div>
                </el-form-item>
                <el-form-item label="许可经营证" style="width: 400px;margin-right: 40px;" prop="name">
                    <el-image style="width: 200px; height: 200px">
                        <div slot="error" class="image-slot">
                            点击上传
                        </div>
                    </el-image>
                    <div class="tips">
                        <p>提示:</p>
                        <p>格式:JPG/PNG</p>
                        <p>尺寸:200x200</p>
                        <p>大小:小于400kb</p>
                    </div>
                </el-form-item>
                <div style="text-align: center">
                    <el-button type="primary">提交</el-button>
                    <el-button @click="dialogTableVisible=false">取消</el-button>
                </div>
            </el-form>
        </el-dialog>
        <buttom></buttom>
    </div>
</template>

<script>
    import newHeader from "@/components/common/newHeader";
    import Buttom from "@/components/common/Buttom";
    import Swipper from "@/components/common/index/Swipper";
    import FlowServiceTitle from "@/components/common/index/FlowServiceTitle";

    export default {
        name: "MyCenterInfo",
        data: function () {
            return {
                dialogTableVisible: false,
                form: {},
                rules: {
                    name: [
                        { required: true, message: '请输入', trigger: 'blur' },
                    ],
                }
            }
        },
        components: {
            newHeader,
            Buttom,
            Swipper,
            FlowServiceTitle,
        },
        methods: {
            editCompanyInfo() {
                this.dialogTableVisible = true
            }
        }
    }
</script>
<style lang="stylus" scoped>
    .tips{
        color #999;
        font-size 12px;
        width 150px;
        display inline-block;
        vertical-align top;
        margin-left 20px;
    }
    .zhizhao {
        display inline-block
    }

    .table-dt {
        display: inline-block;
        width: 120px;
        height: 40px;
        border: 1px solid #666;
        vertical-align: top;
        border-right: none;
        text-align: center;
        line-height: 40px;
    }

    .table-dd {
        display: inline-block;
        width: 240px;
        height: 40px;
        border: 1px solid #666;
        vertical-align: top;
        margin-left 0;
        text-align: center;
        line-height: 40px;
    }

    .table-dd:nth-child(4n+2) {
        border-right none
    }

    .field-title {
        color #333;
        padding-left 20px;
        border-left 3px solid #1a4fcc;
    }

    .site-title {
        border-color: #eee;
        border-width: 1px 0 0;
        border-style: solid;
        margin: 30px 0 20px;
        font-size 24px
        color #333
        padding-left: 0;
    }

    .avatar {
        margin-top: 10%;
    }

    .myAvatar {
        height 600px
        text-align center

        h3 {
            font-size 14px
            color #666
        }
    }

    .box-img-box {
        width 100%
        text-align center
        padding-top: 60px;
    }

    .box:hover {
        background-image: linear-gradient(to bottom right, #1a4fcd, #6deee6);

        .img-bottom {
            background-image: linear-gradient(to bottom right, #1a4fcd, #6deee6);
            display none
        }
    }

    .box {
        position relative
        width 380px
        height 270px
        display inline-block
        box-sizing border-box
        background #ffffff
        border 1px solid #cecece
        margin 15px

        nav {
            font-size 14px
            color #333
        }

        .img-bottom {
            width 100%;
            position absolute;
            bottom 0;
            left 0;
        }

    }

    .my-item {
        border-bottom 1px solid rgb(241, 241, 241)
        padding 10px 0
        font-size 14px
        color #666
    }

    .my-item-visited {
        color #1a4fcc
        background rgba(31, 89, 233, 0.1)
    }
    >>> .el-form-item--medium .el-form-item__content, .el-form-item--medium .el-form-item__label {
        width 100%
    }

    >>> .image-slot {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background: #f5f7fa;
        color: #909399;
        font-size: 14px;
    }


</style>
