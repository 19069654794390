<template>
    <div class="container">
        <div class="container-box">
            <h3>{{title}}</h3>
            <div class="line-box">
                <div class="line line1"></div>
                <div class="line line2"></div>
                <div class="line line3"></div>
            </div>

            <hr/>
            <div class="sub-title">
                <span>{{content}}</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "FlowServiceTitle",
		props:['title','content'],
    }
</script>

<style lang="stylus" scoped>
.container
    width 100%
    height auto
    .container-box
        width 80%
        height 100%
        padding 10px
        margin 0 auto
        text-align center
        h3
            font-size 20px
            padding 0
            line-height 40px
            font-family Microsoft YaHei Regular, Microsoft YaHei Regular-Regular
            margin 0
        hr
            margin 0
            position relative
            margin-top -8px
            z-index -100
        .sub-title
            text-align center
            span
                line-height 30px
                font-size 10px
                color  #787373
        .line-box
            width 80px
            margin 0 auto
            height 12px
            .line
                height 2px
                background-color black
                margin 0 auto
                margin-bottom 2px
            .line1
                width 40px
            .line2
                width 30px
            .line3
                height 1.5px
                width 20px
</style>